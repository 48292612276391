.main-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    text-align: center;
    overflow: hidden;

    background-color: #f6f6f6;
}

.top-container {
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    width: 50vw;
}

.tabs-container {
    display: flex;
    flex-direction: row;
    gap: 10%;
    margin: auto;
    width: 80%;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.tab {
    background-color: white;
    border-radius: 10px;
    text-align: center;
    z-index: 1;
    position: relative;
    cursor: pointer;
    color: white;
    display: flex;

    width: 25vw;
    height: 15vh;
}

.tab-txt {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.svg-aquilone {
    margin-top: 1%;
    width: 10vh;
    margin-bottom: 0;
}

.main-h1 {
    margin-top: 0;
}

h1 {
    font-size: 6vh;
    font-weight: normal;
}

h2 {
    font-weight: normal;
}

.p-big {
    font-size: 2.5vh;
    font-weight: normal;
    margin-top: 0;
}