.mainContainer {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.infoContainer {
    display: flex;
    width: 50vw;
}

.textContainer {
    text-align: left;
    width: 40vw;
    margin: auto;
    margin-left: 3vw;
}

.textContainerLeft {
    text-align: left;
    width: 40vw;
    margin: auto;
    margin-left: 8vw;
}

.textTitle {
    font-weight: bold;
}

.imgContainer {
    display: flex;
    width: 50vw;
}

.imgMain {
    width: 45vw;
    margin: auto;
}
