.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #f6f6f6;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: fit-content;
    /*background-image: url("/src/assets/purple-background.png");*/
    color: black;
}

.txtContainer {
    padding: 0 5vw;
    width: 60vw;
    margin: auto;
    text-align: center;
}

.tabsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 20vh 20vh;
    background-color: #f6f6f6;
    color:#f6f6f6;
    text-align: center;
}

.tab {
    width: 18vw;
    height: 10vh;
    margin: auto;
    display: flex;
    background-color: #ff0000;
    border-radius: 10px;

    cursor: pointer;
}

.tabGiallo {
    width: 18vw;
    height: 10vh;
    margin: auto;
    display: flex;
    background-color: #fbbd02;
    border-radius: 10px;

    cursor: pointer;
}

.tabVerde {
    width: 18vw;
    height: 10vh;
    margin: auto;
    display: flex;
    background-color: #32a754;
    border-radius: 10px;

    cursor: pointer;
}

.tabBlu {
    width: 18vw;
    height: 10vh;
    margin: auto;
    display: flex;
    background-color: #3f86f8;
    border-radius: 10px;

    cursor: pointer;
}

.tabText {
    margin: auto;
}

.mainText {
    font-size: 5vw;
    font-weight: bold;
    margin-top: 2vh;
}

.kidsLogo {
    width: 15vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
}
