.cardContainer {
    background-color: #f9f9f9 ;
    display: flex;
    flex-direction: column;

    height: 60vh;
    flex: 0 0 18vw;
    padding: 0 2vw;

    align-content: center;

}

.cardIcon {
    margin-top: 2vh;
    height: fit-content;
    max-height: 7vh;
}

.cardHeader {
    height: 15%;
}

.cardText {

}