.mainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.titleContainer {
    margin-top: 10vh;
    width: 40vw;

    display: flex;
    flex-direction: column;
}

.videoContainer {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    width: 60vw;
}

.videoPlayer {
    margin: auto;
}

.pageTitle {
    margin-left: 5vw;
}

.qrCode {
    margin-top: 2vh;
    width: 8vw;
    margin-left: -0.5vw;
}
