.collapsibleCard {
    border-radius: 8px;
    width: 50vw;
    overflow: hidden;
    animation-name: fade-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
}

.content {
    overflow: hidden;
    padding: 0 1vw;
}

.collapseContent {
    transition: all 0.6s ease;
    overflow: hidden;
    padding: 0 1vw;
}

.header {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    padding: 0 1vw;

    cursor: pointer;
}

.cardIcon {
    width: 4vw;
}

.cardTitle {
    font-size: 2.5vw;
}

.cardContent {
    font-size: 1.5vw;
}

.expandIcon {
    transition: all 0.3s ease;
    width: 5vw;
    height: 5vh;
    margin-top: 3vh;
    margin-left: auto;
}

@keyframes fade-in {
    0%   {opacity: 0;}
    25%  {opacity: 25%;}
    50%  {opacity: 50%;}
    75%  {opacity: 75%;}
    100% {opacity: 100%;}
}
