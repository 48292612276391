.mainPanel {
    width: 100vw;
    height: 100vh;
    background-color: #f6f6f6;


    display: flex;
    flex-direction: row;
    z-index: -2;
    overflow: hidden;
}


.infoContainer {
    width: 60vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
}

.txtContainer {
    margin: auto;
    width: 25vw;
    text-align: left;

    z-index: 1;
}

.imgContainer {
    width: 40vw;
    height: 100vh;

    overflow: hidden;
}

.imgBig {
    width: 40vw;
    height: 100vh;
    z-index: 1;
}

.imgHome {
    position: absolute;
    left: 2%;
    top: 2%;
    width: 5vw;
    cursor: pointer;
    z-index: 10;
}

.imgHomeWhite {
    position: absolute;
    left: 2%;
    top: 2%;
    width: 5vw;
    cursor: pointer;
    filter: invert(100%);

    z-index: 10;
}

.imgPhone1 {
    position: absolute;
    width: 25vw;
    z-index: 1;
    left: 48vw;
    top: 8vh;
    animation-name: phone;
    animation-duration: 0.5s;
}

.imgPhone12 {
    position: absolute;
    width: 18vw;
    z-index: 1;
    left: 49vw;
    top: 11vh;
    animation-name: phone;
    animation-duration: 0.5s;
}

.section {
    animation-name: phone;
    animation-duration: 0.5s;
}

@keyframes phone {
    0%   {transform: translate(-30px,0); opacity: 0}
    25%  {transform: translate(-20px,0); opacity: 25%}
    50%  {transform: translate(-10px,0); opacity: 50%}
    75%  {transform: translate(-5px,0); opacity: 75%}
    100% {transform: translate(0,0); opacity: 100%}
}

.imgPhone2 {
    position: absolute;
    width: 20vw;
    z-index: 1;
    left: 30vw;
    top: 12vh;
}

.imgPhone3 {
    position: absolute;
    width: 20vw;
    z-index: 1;
    left: 50vw;
    top: 13vh;
}

.arrowIcon {
    transition: all 0.5s ease;
    width: 4vw;
}

.homeButton {
    cursor: pointer;
}

h1 {
    font-size: 3.5vw;
}

h2 {
    font-weight: bold;
}