.mainContainer {
    width: 100vw;
    max-height: 300vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.sideContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
    margin-top: 10vh;
}

.sideText {
    margin-top: 3vh;
    width: 45vw;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    width: 70vw;
    gap: 1vw;
    overflow-x:scroll;
}

