.mainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: hidden;

    background-color: #f6f6f6;
}

.subMainContainer {
    width: 100vw;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/vivi-internet.jpeg");
    background-position: -10vw -8vh;
    color: white;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
    margin-top:5vh;
    width: 60vw;
}

.infoContainer2 {
    display: flex;
    flex-direction: column;
    width: 80vw;
    text-align: center;
    margin: auto;
}

.tabsContainer {
    display: flex;
    flex-direction: row;
    gap: 6vw;

    margin: auto;
    margin-top: 0;
}

.tab {
    display: flex;
    background-color: #ffffff;
    margin: auto;
    border-radius: 10px;
    text-align: center;
    position: relative;
    cursor: pointer;
    color: white;

    width: 13vw;
    height: 9vh;
}

.tabText {
    margin: auto;
}

.textBold {
    font-family: GoogleSans-Bold;
    font-size: 2.5vw;
}
.textBold2 {
    font-family: GoogleSans-Bold;
    font-size: 4.5vw;
}
.textNormal {
    font-weight: normal;

}
.imgContainer {
    display: flex;
    flex-direction: column;

    width: 40vw;
    overflow: hidden;
}
.imgBig {
    width: 80vw;
    height: 100vh;
    transform: translate(-25vw,0);
}
