.main-panel {
    width: 100vw;
    height: 100vh;
    background-color: #f6f6f6;


    display: flex;
    flex-direction: row;
    z-index: -2;
    overflow: hidden;
}


.info-container {
    width: 60vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

}

.txt-container {
    margin: auto;
    width: 25vw;
    text-align: left;

    z-index: 1;
}

.img-container {
    width: 40vw;
    height: 100vh;

    overflow: hidden;
}

.img-big {
    width: 40vw;
    height: 100vh;
    z-index: 1;
}

.img-free-container {
    position: absolute;
}



.img-home {
    position: absolute;
    left: 2%;
    top: 2%;
    width: 5vw;
    cursor: pointer;
    z-index: 10;
}

.img-home-white {
    position: absolute;
    left: 2%;
    top: 2%;
    width: 5vw;
    cursor: pointer;
    filter: invert(100%);

    z-index: 10;
}

.img-phone-1 {
    position: absolute;
    width: 25vw;
    z-index: 1;
    left: 48vw;
    top: 8vh;
}

.img-phone-2 {
    position: absolute;
    width: 20vw;
    z-index: 1;
    left: 30vw;
    top: 12vh;
}

.img-phone-3 {
    position: absolute;
    width: 20vw;
    z-index: 1;
    left: 50vw;
    top: 13vh;
}

.home-button {
    cursor: pointer;
}

h1 {
    font-size: 3.5vw;
}

h2 {
    font-weight: bold;
}