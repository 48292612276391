.mainContainer {
    width: 100vw;
    max-height: 300vh;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}

.cardsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    margin-left: 0vw;
    width: 70vw;
    height: 95vh;
}

.titleContainer {
    margin-top: 10vh;
    width: 30vw;

    display: flex;
    flex-direction: column;
}

.card {
    animation-name: fade-in;
    animation-duration: 1s;
}

@keyframes fade-in {
    0%   {opacity: 0%;}
    25%  {opacity: 25%;}
    50%  {opacity: 50%;}
    75%  {opacity: 75%;}
    100% {opacity: 100%;}
}

.pageTitle {
    margin-left: 5vw;
}

