body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: GoogleSans-Regular;
}

h2 {
  font-family: GoogleSans-Regular;
}

h3 {
  font-family: GoogleSans-Regular;
}

h4 {
  font-family: GoogleSans-Regular;
}

p {
  font-family: GoogleSans-Regular;
}

ul {
  list-style: none;
}

li {
  /** the image will be vertically aligned in the center **/
  background: url("./assets/google-icons/li-green.svg") left center no-repeat;
  /** move the text to the right **/
  padding-left: 4vw;
}